import {request} from "../_helpers";

export const organisations = {
    namespaced: true,
    state: {
        organisations: [],
        organisations_tree: [],     
        filteredOrgTree: [],
        showChildrenOrgsObject: {}
    },
    getters: {
        current: state => id => {
            const result = state.organisations.filter(organisation => {
                return organisation.id === parseInt(id);
            });
            return (result) ? result[0] : null;
        },
        all: state => {
            return state.organisations;
        },
        all_tree: state => {
            return state.organisations_tree;
        },
        filteredOrgTree: state => {
            return state.filteredOrgTree;
        },
        showChildrenOrgsObject: state => {
            return state.showChildrenOrgsObject;
        }
    },
    actions: {
        async all(store) {
            return await request(`${process.env.VUE_APP_AUTH_URL}/organisation?page=all`, 'get')
                .then(response => {
                    store.commit('all', response.data)
                })
        },
        async all_tree(store) {
            return await request(`${process.env.VUE_APP_AUTH_URL}/organisation_tree?page=all`, 'get')
                .then(response => {
                    store.commit('all_tree', response.data)
                })
        },
        async update(store, data) {
            return await
                request(`${process.env.VUE_APP_AUTH_URL}/organisation/${data.id}`, 'put', data)
                    .then(response => {
                        store.commit('update', response.data);
                        store.dispatch(
                          'alert/alerts',
                          {
                            type: 'success',
                            message: `Организация ${data.name} отредактирована`,
                          },
                          { root: true }
                        );
                        return response
                    }).finally(() => {
                        request(
                            `${process.env.VUE_APP_AUTH_URL}/organisation_tree?page=all`,
                            'GET'
                        ).then((res) => {
                            const sortInfo = res.data.sort(
                            (org1, org2) => org1.id - org2.id
                            );
                            store.commit('updateFilteredOrgTree', sortInfo);
                        });
                    })
        },
        async delete(store, data) {
            return await
                request(`${process.env.VUE_APP_AUTH_URL}/organisation/${data.id}`, 'delete')
                    .then(response => {
                        store.dispatch('all');
                        store.dispatch(
                        'alert/alerts',
                        {
                            type: 'success',
                            message: `Организация ${data.name} удалена`,
                        },
                        { root: true }
                        );
                        return response;
                    }).finally(() => {
                        request(
                            `${process.env.VUE_APP_AUTH_URL}/organisation_tree?page=all`,
                            'GET'
                        ).then((res) => {
                            const sortInfo =
                            res.data.sort(
                                (org1, org2) =>
                                org1.id - org2.id
                            );
                            store.commit(
                            'updateFilteredOrgTree',
                            sortInfo
                            );
                        });
                    })
        },
        async create(store, data) {
            return await request(`${process.env.VUE_APP_AUTH_URL}/organisation`, 'post', data)
                    .then(response => {
                        store.dispatch('all');
                        store.dispatch(
                            'alert/alerts',
                            {
                            type: 'success',
                            message: `Организация ${data.name} создана`,
                        },
                        { root: true }
                        );
                        return response;
                    })
                    .finally(() => {
                        request(
                            `${process.env.VUE_APP_AUTH_URL}/organisation_tree?page=all`,
                            'GET'
                        ).then((res) => {
                            const sortInfo =
                            res.data.sort(
                                (org1, org2) =>
                                org1.id - org2.id
                            );
                            store.commit(
                            'updateFilteredOrgTree',
                            sortInfo
                            );
                        });
                    })

        },
    },
    mutations: {
        all(state, all) {
            state.organisations = all;
            state.organisations.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        },
        all_tree(state, all) {
            state.organisations_tree = all;
            state.organisations_tree.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        },
        updateFilteredOrgTree(state, updateInfo) {
            state.filteredOrgTree = updateInfo;
        },
        updateShowChildrenOrgsObject(state, { id, showAllChildrenOrgs }) {
            state.showChildrenOrgsObject = {
            ...state.showChildrenOrgsObject,
            [id]: showAllChildrenOrgs
            };
        },
        update(state, update) {
            let updateIndex;
            state.organisations.forEach((organisation, index) => {
                if(organisation.id === update.id)
                    updateIndex = index;
            });
            state.organisations.splice(updateIndex, 1, update);
            state.organisations.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        },
    }
}
